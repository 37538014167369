<template>
  <div class="SportComingSoon">
    <section class="section container">
      <!--<h1 class="title is-2 has-text-secondary" v-html="$t('landing.sports_not_available')"/> -->

      <div class="columns is-mobile is-multiline">
        <div class="column" v-if="sport==='swimming'">
          <div class="sport">
            <img src="~@/assets/landing/Swimming.png"/>
            <div class="sport-text">
              <h3 class="title is-3 is-size-5-touch is has-text-white">{{ $t('landing.swimming') }}</h3>
              <h4 class="subtitle is-6 has-text-white">{{ $t('landing.in_dev') }}</h4>
            </div>
          </div>
        </div>
        <div class="column" v-if="sport==='running'">
          <div class="sport">
            <img src="~@/assets/landing/Running.png"/>
            <div class="sport-text">
              <h3 class="title is-3 is-size-5-touch has-text-white">{{ $t('landing.running') }}</h3>
              <h4 class="subtitle is-6 has-text-white">(2024)</h4>
            </div>
          </div>
        </div>
        <div class="column" v-if="sport==='triathlon'">
          <div class="sport">
            <img src="~@/assets/landing/Triathlon.png"/>
            <div class="sport-text">
              <h3 class="title is-3 is-size-5-touch has-text-white">{{ $t('landing.triathlon') }}</h3>
              <h4 class="subtitle is-6 has-text-white">{{ $t('landing.coming_soon') }}</h4>
            </div>
          </div>
        </div>

        <div class="column box has-background-primary is-full-mobile">
          <h1 class="title is-2 has-text-white" v-html="$t('landing.notify_me_title')"/>
          <p class="has-text-white is-size-5 has-text-left max-width700 m-auto" v-html="$t('landing.notify_me_text')"/>

          <AddMail class="is-inline-block mt-6" inputClass="has-text-white has-background-primary-light" buttonType="is-white"
            buttonClass="has-text-primary" :buttonText="$t('landing.notify_me')" list="otherSports" :customFields="sport ? { e3_T: sport } : {}"/>
        </div>
      </div>

    </section>


  </div>
</template>

<script>
import AddMail from './AddMail.vue';

export default{
  name: 'SportComingSoon',
  computed: {
    sport(){ return this.$route.query.sport; }
  },
  components: {
    AddMail,
  },
}
</script>

<style lang="scss" scoped>
.sport{
  position: relative;
  text-align: center;
  color: white;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  min-width: 120px;
}
.sport-text{
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.max-width700{
  max-width: 700px;
}
</style>
