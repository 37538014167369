<template>
  <div>
    <b-field>
      <b-input v-model="email" size="is-large-touch" :customClass="inputClass + ' is-rounded'" :placeholder="$t('account.email')"/>
      <p class="control">
        <b-button :type="buttonType" size="is-large-touch" :class="buttonClass" class="is-rounded" :label="'>> ' + buttonText" @click.native="submit"/>
      </p>
    </b-field>
    <p v-if="success" class="has-text-success has-background-white is-inline-block m-auto p-2">{{ $t('landing.mail_notified') }}</p>
    <p v-if="error" class="has-text-danger has-background-white is-inline-block m-auto p-2">{{ $t(errormsg) }}</p>
  </div>
</template>

<script>

export default{
  name: 'AddMail',
  props: {
    inputClass: { type: String, default: '' },
    buttonClass: { type: String, default: '' },
    buttonType: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    list: { type: String },
    customFields: { type: Object, default: () => null },
  },
  data(){
    return {
      error: false,
      success: false,
      errormsg: '',
      email : '',
    };
  },
  methods: {
    checkMail(email){
      return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    },

    checkData(){
      this.errormsg = 'errors.account.';
      if(this.email === ''){
        this.errormsg += 'enter_email';
        this.error = true;
        return false;
      }
      if(!this.checkMail(this.email)){
        this.errormsg += 'invalid_email';
        this.error = true;
        return false;
      }
      if(this.username == ""){
        this.errormsg += 'enter_name';
        this.error = true;
        return false;
      }
      if(this.checkMail(this.username)){
        this.errormsg += 'username_not_email';
        this.error = true;
        return false;
      }
      this.errormsg = '';
      return true;
    },


    async submit(){
      this.error = false;
      if(this.checkData()){
        try{
          var data = { email: this.email, list: this.list,  lang: this.$t('lang'), custom_fields: this.customFields };

          await this.post('/add_mail_to_list', data);
          this.success = true;
        }catch(err){
          this.error = true;
          this.errormsg = err;
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
